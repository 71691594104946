import React from 'react';
//import { Helmet } from "react-helmet"
import Layout from '../layout';
import { graphql } from 'gatsby';
import RequestEstimateForm from '../components/requestEstimateForm';

import Obfuscate from 'react-obfuscate';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO/SEO';

if (typeof window !== `undefined`) {
  require('bootstrap/dist/css/bootstrap.min.css');
}

export default function OurClients(props) {
  //const postEdges = props.data.allMarkdownRemark.edges;
  // const [state, setState] = React.useState({})

  return (
    <Layout>
      <SEO title="Request an Estimate" />
      <div className="site-content container">
        <div className="row">
          <div className="col-sm-7">
            <p>Get a free estimate usually within 24 hours!</p>

            <p>
              Call us: <Obfuscate tel="(818) 738-7319" />
              <br />
              Email us: <Obfuscate email="info@custompropshop.com" />
            </p>

            <p className="mb-3">Or fill out the form below:</p>

            <RequestEstimateForm />
          </div>

          <div className="col-sm-1"></div>

          <div className="col-sm-4">
            <h5 className="side-testimonial text-justify">
              "TOTALLY TOTALLY AWESOME! Thank you very much. The work and the
              way it was installed was fantastic! I’m so pleased thank you!!!!"
            </h5>

            <h5 className="side-testimonial text-right">
              <i>Alex M., Entertainment Industry</i>
            </h5>

            <h5 className="side-testimonial mt-5 text-justify">
              "The [prop] installation is complete and it looks amazing. Thank
              you so much to everyone for helping make it possible."
            </h5>

            <h5 className="side-testimonial text-right mb-5">
              <i>Rachel S., Events Manager</i>
            </h5>
            <div className="text-center">
              <GatsbyImage
                image={props.data.sandSnowman.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query RequestEstimateQuery {
    sandSnowman: file(relativePath: { eq: "sand-snowman.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, placeholder: BLURRED)
      }
    }
  }
`;
